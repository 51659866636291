/* This example requires Tailwind CSS v2.0+ */
import {CheckIcon} from '@heroicons/react/outline'
import Link from "next/link";


const tiers = [
  {
    name: 'Data Insight Pro',
    href: 'https://forms.gle/FP3Ntb2yAubpemnt8',
    priceMonthly: 399.99,
    description: 'Data Insight Pro offers secure data management and easy integration for data visualization and future opportunities with expert analysis support.',
    features: [
      'Secure Data Management Hub',
      'Seamless Data Integration',
      'Visualize Data with Ease',
      'Unlock Future Opportunities',
      'Expert Data Analysis Support',
    ],
  },
  {
    name: 'Data Mastery Elite',
    href: 'https://forms.gle/FP3Ntb2yAubpemnt8',
    priceMonthly: 999.99,
    description: 'Data Mastery Elite offers advanced data analysis features with predictive analytics, visualization, and 24/7 expert support.',
    features: [
      'All features from Data Insight Pro',
      'Drive Business Decisions with Data',
      'Advanced Predictive Analytics',
      'Advanced Data Visualization Tools',
      '24/7 Expert Support and Insights',
    ],
  },
]

export default function Example() {
  return (
    <div className="bg-indigo-700">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              Pricing Plans
            </h2>
            <p className="text-xl text-gray-300">
              Unlock the full potential of your data with our pricing plans
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-white sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-indigo-700"/>
          <div className="relative z-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                      ${tier.priceMonthly}
                      <span className="ml-1 text-2xl font-medium text-gray-500">/mo</span>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                  </div>
                  <div
                    className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul role="list" className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true"/>
                          </div>
                          <p className="ml-3 text-base text-gray-700">{feature}</p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <Link href={tier.href}>
                        <a
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-indigo-700"
                          aria-describedby="tier-standard"
                        >
                          Get started
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl">
            <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <h3
                    className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                    Discounted
                  </h3>
                </div>
                <div className="mt-4 text-lg text-gray-600">
                  Budget-friendly solution: Access all standard license features for solo projects with less than $20k gross revenue for just <span className="font-semibold text-gray-900">$129.99</span>.
                </div>
              </div>
              <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <Link href="https://forms.gle/FP3Ntb2yAubpemnt8">
                  <a
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                  >
                    Buy Discounted License
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
