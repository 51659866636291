/* This example requires Tailwind CSS v2.0+ */
import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'

const features = [
  {
    name: 'Secure Data Management Hub',
    icon: CloudUploadIcon,
    description: 'Store, manage and centralize your data in a secure platform, supporting data collection from multiple sources for effective data analysis.'
  },
  {
    name: 'Seamless Data Integration',
    icon: LockClosedIcon,
    description: 'Import data from various sources, with the ability to clean and organize data to meet higher standards for analysis and usage.'
  },
  {
    name: 'Visualize Data with Ease',
    icon: RefreshIcon,
    description: 'Visualize your data through graphs and tables, making it easier to understand and improving the efficiency of data analysis.'
  },
  {
    name: 'Unlock Future Opportunities',
    icon: ShieldCheckIcon,
    description: 'Predict data trends and patterns, allowing your business to discover future opportunities through data analysis.'
  },
  {
    name: 'Expert Data Analysis Support',
    icon: CogIcon,
    description: 'Gain expert-level data analysis support and insights, helping your business understand the impact of data on decision-making and providing valuable insights.'
  },
  {
    name: 'Drive Business Decisions with Data',
    icon: ServerIcon,
    description: 'Get actionable recommendations and decisions, helping your business better leverage data-driven decision-making.'
  },
]

export default function Example() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Focus on your business</h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Unlock the Potential of Your Business with Data-Driven Insights
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Make informed decisions quickly with real-time visualization and predictive analytics. Maximize business potential with expert insights and recommendations.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
